<template>
  <div>
    <div id="top">
      <!-- <div style="height: 25px; background: #fff"></div> -->
      <van-search
        v-model="value"
        shape="round"
        background="#fff"
        placeholder="搜索"
        @search="Search"
      />
    </div>
    <van-tree-select
      id="sel"
      style="margintop: 10px"
      :style="{ height: height + 'px' }"
      :items="categoryList"
      :main-active-index.sync="active"
      @click-nav="clickNav"
    >
      <template #content>
        <div class="outside">
          <!-- <div class="icon_div">
            <span
              @click="selcet(i,item)"
              v-for="(item, i) in navItem.children"
              :class="(i === cur || (isCur && i == 0))? 'icon_span_act' : 'icon_span'"
              :key="i"
              >{{item.text}}</span
            >
          </div> -->
          <div
            class="outside-item"
            v-for="(item, index) in twoarr"
            :key="index"
          >
            <div class="title">
              <router-link :to="'/shop/goods/list?categoryId=' + item.id">
                {{ item.text }}
              </router-link>
            </div>
            <div class="all">
              <router-link
                class="content"
                v-for="(item, i) in twoarr[index].children"
                :key="i"
                :to="'/shop/goods/list?categoryId=' + item.id"
              >
                <div class="all-img">
                  <img :src="item.li_attr.ImageUrl" alt="" />
                </div>
                <div>{{ item.text }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </van-tree-select>
  </div>
</template>

<script>
import { queryGoodsCategoryTree } from "@/api/shop";
import { queryStudentInfoById } from "@/api/bbs";
export default {
  data() {
    return {
      active: 0,
      value: "",
      items: [
        { text: "艺术功能" },
        { text: "艺术风格" },
        { text: "艺术种类" },
        { text: "文创艺品" },
        { text: "画具画材" },
      ],
      height: "",
      cur: "",
      isCur: true,
      categoryList: [],
      twoarr: [], //二级
      navItem: {},
      curCategory: [],
      user: {},
    };
  },
  created() {
    if (
      this.$route.query.UserId != undefined &&
      this.$route.query.UserId != ""
    ) {
      this.user.Id = this.$route.query.UserId;
      this.getQueryUserInfo();
    }
  },
  mounted() {
    //  整个屏幕高度
    let AllHeight = document.documentElement.clientHeight;
    //  屏幕剩余高度
    this.height =
      AllHeight - document.getElementById("top").offsetHeight - 10 - 50;
    this.postQeryShopGoodsCategoryList();
  },
  methods: {
    selcet(i, item) {
      this.isCur = false;
      this.cur = i;
      this.curCategory = item;
      console.log(item);
    },
    // 列表数据
    async postQeryShopGoodsCategoryList() {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      this.categoryList = res.response;
      this.navItem = this.categoryList;
      // this.curCategory =this.navItem.children != null? this.navItem.children[0]:{};
      // this.curCategory =this.navItem.children != null? this.navItem.children[0]:{};
      this.twoarr = this.navItem[0].children;
      this.therr = this.navItem[0].children[0].children;
      console.log(this.navItem, 789798);
    },
    // 列表数据
    Search() {
      console.log(this.value);
      this.$router.push({
        path: "/shop/goods/list",
        query: {
          key: this.value,
        },
      });
    },
    clickNav(index) {
      console.log(index, 88);
      this.isCur = true;
      // this.navItem = this.categoryList[index];
      this.twoarr = this.navItem[index].children;
      this.curCategory =
        this.navItem.children != null ? this.navItem.children[0] : {};
    },
    // 用户详情
    async getQueryUserInfo() {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      console.log(this.user);
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
  },
};
</script>

<style lang="scss" scoped>
.van-sidebar-item--select::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 25px;
  background-color: #040404;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 0px 100px 100px 0px;
  content: "";
}
.van-sidebar-item {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.van-sidebar-item--select {
  font-weight: 700;
}
.van-sidebar {
  width: 100px;
}
.van-tree-select__nav {
  flex: none;
}
.outside {
  width: 92%;
  margin: 0 auto;
  .icon_div {
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
    .icon_span_act {
      font-size: 12px;
      color: #ff4511;
      padding: 4px 13px;
      border: 1px solid #ff4511;
      border-radius: 20px;
      background: #feece7;
      margin-right: 5px;
      margin-top: 10px;
    }
    .icon_span {
      font-size: 12px;
      color: #333333;
      padding: 4px 13px;
      border: 1px solid #f6f6f6;
      border-radius: 20px;
      background: #f6f6f6;
      margin-right: 5px;
      margin-top: 10px;
    }
  }
}

.title {
  a {
    font-size: 16px;
    color: #323232;
    height: 50px;
    line-height: 50px;
    font-weight: 800;
  }
}
.all {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .content {
    width: 28%;
    text-align: center;
    margin-bottom: 15px;
    div {
      margin-top: 12px;
      color: #000000;
      font-size: 14px;
    }
  }
  .all-img {
    width: 4.125rem;
    height: 4.125rem;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      margin: 0 auto;
      display: inline-block;
    }
  }
}
.van-sku-actions {
  background: #fff;
}
/* 如果最后一行是2个元素 */
.content:last-child:nth-child(3n - 1) {
  margin-right: calc(32% + 8% / 2);
}
</style>